<script>
  import { getPrices } from "@/api/Packages";
  import { getDiscounts } from "@/api/Discounts";

  export default {
    name: 'price',
    props: ['package', 'aditional_agent', 'version', 'cycle', 'discount'],
    created: function () {
      this.fetchData();
    },
    data() {
      return {
        discounts: {
          plan: null,
          aditional_agent: null
        },
        discountItems: [],
        prices: {
          plan: 0.00,
          aditional_agent: 0.00,
          whatsapp: 0.00
        },
        ui: {
          loading: false,
        }
      };
    },
    methods:{
      fetchData: function(){
        let vm = this

        getDiscounts().then(response => {
          vm.discountItems = response
        })

        this.setDiscount()
      },
      getPrices: function () {
        let vm = this;
        this.ui.loading = true;

        getPrices(this.package, this.cycle, this.version).then(response => {
          vm.prices.plan            = response.plan ? parseFloat(response.plan.price) : 0.00
          vm.prices.aditional_agent = response.aditional_agent ? parseFloat(response.aditional_agent.price) : 0.00

          vm.ui.loading = false;
        })
      },
      setDiscount: function(){
        let vm = this

        var agentDisc = this.discountItems.find(function(item) {
          return (item.type === 'agent' && item.package === vm.package && item.quantity <= vm.aditional_agent);
        });

        if(typeof agentDisc !== "undefined"){
          this.discounts.aditional_agent = agentDisc
        }else{
          this.discounts.aditional_agent = this.discountItems.find(function(item) {
            return (item.type === 'agent' && item.package === vm.package && item.quantity === null);
          });
        }

        this.discounts.plan = this.discountItems.find(function(item) {
          return (item.type === 'package' && item.package === vm.package);
        });
      }
    },
    computed:{
      baseAmount: function () {
        return this.prices.plan + (this.prices.aditional_agent * this.aditional_agent)
      },
      discountAmount: function () {
        if(this.discount){
          return this.discountPlan + this.discountAgent
        }else{
          return 0.00
        }
      },
      discountPlan: function () {
        if(typeof this.discounts.plan === 'object'){
          return this.prices.plan * (this.discounts.plan.value / 100)
        }else{
          return 0.00
        }
      },
      discountAgent: function () {
        if(typeof this.discounts.aditional_agent === 'object'){
          return (this.prices.aditional_agent * this.aditional_agent ) * (this.discounts.aditional_agent.value / 100)
        }else{
          return 0.00
        }
      },
      finalAmount: function () {
        if(this.baseAmount > this.discountAmount){
          return this.baseAmount - this.discountAmount
        }else{
          return 0.00
        }
      }
    },
    watch: {
      package: function () {
        this.getPrices()
        this.setDiscount()
      },
      cycle: function () {
        this.getPrices()
      },
      version: function () {
        this.getPrices()
      },
      aditional_agent: function () {
        this.setDiscount()
      }
    }
  }
</script>

<template>
    <span>{{ $n(finalAmount, 'currency', 'brl')}} <small>/{{ $t(`content.periods.${cycle}`) }}</small></span>
</template>
