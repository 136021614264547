<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getSubscription, listCancellationReasons, requestCancellation, orderSubscription, activateDemo } from "@/api/Subscriptions";
  import { Subscription } from "@/models/Subscription";
  import { SubscriptionCancellation } from "@/models/SubscriptionCancellation";
  import Price from "@/router/views/subscriptions/components/price"

  import DatePicker from "vue2-datepicker";
  import Multiselect from "vue-multiselect";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Assinaturas",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, DatePicker, Multiselect, Price },
    data() {
      return {
        title: "Assinaturas",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Assinaturas",
            href: "/subscriptions",
          },
          {
            text: "Detalhes",
            active: true,
          },
        ],
        subscription: new Subscription(),
        reasons: [],
        packages: [
          {"id":1,"name":"Starter","slug":"free","agent":1,"order":1,"active":1,"visible":1},
          {"id":2,"name":"Básico","slug":"basic","agent":1,"order":2,"active":1,"visible":1},
          {"id":3,"name":"Intermediário","slug":"intermediate","agent":1,"order":3,"active":1,"visible":1},
          {"id":4,"name":"Avançado","slug":"advanced","agent":1,"order":4,"active":1,"visible":1}
        ],
        cycles:[
          {
            name: 'Mensal',
            slug: 'month'
          },
          {
            name: 'Anual',
            slug: 'year'
          }
        ],
        reasonSelected: null,
        packageSelected: null,
        cycleSelected: null,
        forms: {
          cancellation: {
            reason_id: null,
            requester: "",
            details: "",
            cancellation_date: null
          },
          order:{
            id: null,
            company_id: null,
            package: null,
            cycle: 'month',
            aditional_agent: 0,
            discount: true
          },
          demo:{
            company_id: null,
            package: null,
            cycle: 'month',
            aditional_agent: 0,
            discount: true
          }
        },
        ui:{
          forms: {
            cancellation: {
              disabled: false
            },
            demo: {
              disabled: false
            },
            order: {
              disabled: false
            }
          }
        }
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getSubscription(this.$route.params.id).then(response => {
          vm.subscription = new Subscription(response)
          
          vm.forms.order.id = response.id
          vm.forms.order.company_id = response.company.id
          vm.forms.demo.company_id = response.company.id
        })
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      showModalCancellation(){
        let vm = this
        this.$bvModal.show('modal-cancellation');

        listCancellationReasons().then(response => {
          vm.reasons = response
        });
      },
      handleCancellation(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.requestCancellation()
            this.$bvModal.hide('modal-cancellation')
          }
        });
      },
      requestCancellation(){
        let vm = this
        if (vm.ui.forms.cancellation.disabled === false) {
          vm.ui.forms.cancellation.disabled = true;

          vm.$awn.async(
            requestCancellation(vm.subscription.id, vm.forms.cancellation),
            function (response) {
              vm.ui.visible = false
              vm.ui.forms.cancellation.disabled = false
              vm.subscription.cancellations.unshift(new SubscriptionCancellation(response))
              
              vm.$awn.success('Operação realizada com sucesso.')
            }, function () {
              vm.$awn.warning('Ocorreu um erro ao efetuar a operação. Favor tentar novamente.')
              vm.ui.forms.cancellation.disabled = false
            }
          );
        }
      },
      showModalOrder(){
        this.$bvModal.show('modal-order');
      },
      showModalDemo(){
        this.$bvModal.show('modal-demo');
      },
      handleDemo(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.activateDemo()
            this.$bvModal.hide('modal-demo')
          }
        });
      },
      activateDemo(){
        let vm = this;

        if(this.ui.forms.demo.disabled === false){
          this.ui.forms.demo.disabled = true

          this.$awn.async(
            activateDemo(this.subscription.id, this.forms.demo),
            function (result) {
              vm.$awn.success("Dados atualizados com sucesso.")
              vm.ui.forms.demo.disabled = false;
              vm.subscription = new Subscription(result)
            },
            function (error) {
              vm.$awn.warning(error)
              vm.ui.forms.demo.disabled = false;
            }
          )
        }
      },
      handleOrder(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.createOrder()
            this.$bvModal.hide('modal-order')
          }
        });
      },
      createOrder(){
        let vm = this;

        if(this.ui.forms.order.disabled === false){
          this.ui.forms.order.disabled = true

          this.$awn.async(
              orderSubscription(this.subscription.id, this.forms.order),
              function (response) {
                vm.$awn.success("Operação realizada com sucesso.");

                if(typeof response.order !== "undefined" && response.order){
                  vm.ui.forms.order.disabled = false;
                  vm.$router.push(`/orders/${response.order.id}`)
                }else{
                  vm.subscription = new Subscription(response)
                }
              },
              function (error) {
                vm.$awn.warning(error)
                vm.ui.forms.order.disabled = false;
              }
          )
        }
      }
    },
    computed: {
      hasCancellations: function () {
        return this.subscription.cancellations.length > 0
      },
      partner: function (){
        return JSON.parse(localStorage.getItem('partner'));
      }
    },
    watch: {
      reasonSelected: function (value) {
        this.forms.cancellation.reason_id = value.id
      },
      packageSelected: function (value) {
        this.forms.order.package = value.slug
        this.forms.demo.package = value.slug
      },
      cycleSelected: function (value) {
        this.forms.order.cycle = value.slug
        this.forms.demo.cycle = value.slug
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Detalhes
  
          <b-dropdown right size="sm" variant="secondary" style="float:right;">
            <template slot="button-content">
              Ações
              <i class="bx bx-dots-vertical" />
            </template>
            <b-dropdown-item @click="showModalDemo()" v-if="partner.type === 'reseller' && subscription.demo === false && (subscription.package_id === 1 || subscription.package_id === 6)">Ativar Demo</b-dropdown-item>
            <b-dropdown-item @click="showModalOrder()">Contratar/Alterar Plano</b-dropdown-item>
            <b-dropdown-item @click="showModalCancellation()">Solicitar Cancelamento</b-dropdown-item>
          </b-dropdown>
        </h4>
  
        <div class="table-responsive">
          <table class="table">
            <tbody>
            <tr>
              <th scope="row">
                Empresa:
              </th>
              <td>
                <router-link
                  tag="a"
                  :to="`/companies/${subscription.company.id}`"
                  class="text-primary"
                >
                  {{ subscription.company.name }}
                </router-link>
              </td>
              <th scope="row">
                Plano:
              </th>
              <td>{{ $t(`content.packages.${subscription.package.slug}`)}}</td>
              <th scope="row">
                Criada em:
              </th>
              <td>{{ $d(subscription.created_at, 'short')}}</td>
            </tr>
            <tr>
              <th scope="row">
                Agents:
              </th>
              <td>{{ subscription.agent }} <small class="text-muted">({{ subscription.aditional_agent }} adicionais)</small></td>
              <th scope="row">
                Vencimento:
              </th>
              <td>{{ $d(subscription.period_end, 'short') }}</td>
              <th scope="row">
                Demo:
              </th>
              <td>
                <span v-if="subscription.demo" class="badge badge-danger">Sim</span>
                <span v-else class="badge badge-warning">Não</span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                Ciclo de Pagamento:
              </th>
              <td>{{ $t(`content.cycles.${subscription.cycle}`)}}</td>
              <th scope="row">
                Método de Pagamento:
              </th>
              <td>{{ $t(`global.methods.${subscription.payment_method}`) }}</td>
              <th scope="row">
                Status:
              </th>
              <td v-if="!subscription.suspended">
                <span v-if="!subscription.is_due" class="badge badge-success">Ativa</span>
                <span v-else class="badge badge-warning">Vencida</span>
              </td>
              <td v-else>
                <span class="badge badge-danger">Suspensa</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Itens
        </h4>
  
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th style="width: 70px;">
                Qtd.
              </th>
              <th>Item</th>
              <th class="text-right">
                Preço
              </th>
              <th class="text-right">
                Desconto
              </th>
              <th class="text-right">
                Subtotal
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in subscription.items" v-bind:key="item.id" v-show="item.quantity > 0">
              <td>{{ item.quantity }}x</td>
              <td>{{ item.description }}</td>
              <td class="text-right">
                {{ $n(item.price, 'currency', subscription.company.currency) }}
              </td>
              <td class="text-right">
                <p class="text-truncate text-right">
                  <span class="text-dark" v-if="item.discount > 0">{{item.discount}}%</span>
                  <span class="text-dark" v-else>---</span>
                </p>
                <p class="text-muted mb-0 text-right" v-if="item.discount > 0">-{{ item.discount_amount ? $n(item.discount_amount, 'currency', subscription.company.currency) : '---' }}</p>
              </td>
              <td class="text-right">
                {{ $n(item.amount, 'currency', subscription.company.currency) }}
              </td>
            </tr>
            <tr>
              <td
                colspan="4"
                class="text-right"
              >
                Sub Total
              </td>
              <td class="text-right">
                {{ $n(subscription.subtotal, 'currency', subscription.company.currency) }}
              </td>
            </tr>
            <tr>
              <td
                colspan="4"
                class="border-0 text-right"
              >
                <strong>Desconto</strong>
              </td>
              <td class="border-0 text-right">
                {{ $n(subscription.discount, 'currency', subscription.company.currency) }}
              </td>
            </tr>
            <tr>
              <td
                colspan="4"
                class="border-0 text-right"
              >
                <strong>Total</strong>
              </td>
              <td class="border-0 text-right">
                <h4 class="m-0">
                  {{ $n(subscription.total, 'currency', subscription.company.currency) }}
                </h4>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card" v-if="hasCancellations">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Solicitações de Cancelamento
        </h4>
      
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th class="min">#</th>
              <th>Motivo</th>
              <th>Solicitado Por</th>
              <th class="text-center">Status</th>
              <th class="min">Data para cancelamento</th>
              <th>Solicitado em</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in subscription.cancellations" v-bind:key="item.id">
              <th scope="row">
                {{ item.id }}
              </th>
              <td>{{ item.reason.name }}</td>
              <td>{{ item.requester }}</td>
              <td class="text-center"><span :class="item.class">{{ $t(`global.status.${item.status}`) }}</span></td>
              <td class="min">{{ $d(item.cancellation_date, 'short') }}</td>
              <td class="min">{{ $d(item.created_at, 'long') }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <b-modal
      id="modal-cancellation"
      centered
      title="Solicitar Cancelamento"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Salvar'"
      @ok="handleCancellation"
    >
    
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(requestCancellation)"
          class="form-horizontal"
        >
          <validation-provider
            name="Razão"
            :rules="{ required: true }"
            v-slot="reason"
          >
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Razão"
              label-for="input-cancellation-reason"
            >
              <multiselect v-model="reasonSelected"
                           id="input-cancellation-reason"
                           name="reason"
                           :state="getValidationState(reason)"
                           track-by="id"
                           label="name"
                           :options="reasons"
                           :searchable="true"
                           :allow-empty="false"
                           placeholder=""
                           >
                <template slot="singleLabel" slot-scope="{ option }">
                  <div v-if="option.id">{{ option.name }}</div>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <div v-if="option.id">{{ option.name }}</div>
                </template>
              </multiselect>
              <b-form-invalid-feedback>{{ reason.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="Data"
            :rules="{ required: true }"
            v-slot="date"
          >
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Data"
              label-for="input-cancellation-date"
            >
              <date-picker
                v-model="forms.cancellation.cancellation_date"
                id="input-cancellation-date"
                name="date"
                :first-day-of-week="1"
                lang="pt-BR"
                :format="'DD/MM/YYYY'"
                :value-format="'YYYY-MM-DD'"
                :default-value="new Date()"
                :disabled-date="(date) => {return date.getTime() <= new Date().getTime()}"
                :editable="false"
                :clearable="false"
                
                :state="getValidationState(date)"
              />
              <b-form-invalid-feedback>{{ date.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="Solicitante"
            :rules="{ required: true }"
            v-slot="requester"
          >
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Solicitante"
              label-for="input-cancellation-requester"
            >
              <b-form-input
                v-model="forms.cancellation.requester"
                id="input-cancellation-requester"
                name="requester"
                :state="getValidationState(requester)"
              />
              <b-form-invalid-feedback>{{ requester.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <b-form-group
            label-cols-sm="2"
            label-cols-lg="2"
            label="Detalhes"
            label-for="input-cancellation-details"
          >
            <b-form-textarea
              v-model="forms.cancellation.details"
              id="input-cancellation-details"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Salvar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-user')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  
    <b-modal
      id="modal-order"
      centered
      title="Contratar/Alterar Plano"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Salvar'"
      @ok="handleOrder"
    >
    
      <validation-observer ref="observer" v-slot="{ handleSubmitOrder }">
        <b-form
          @submit.stop.prevent="handleSubmit(createOrder)"
          class="form-horizontal"
        >
          <validation-provider
            name="Plano"
            :rules="{ required: true }"
            v-slot="plan"
          >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Plano"
              label-for="input-order-plan"
            >
              <multiselect v-model="packageSelected"
                           id="input-order-plan"
                           name="package"
                           track-by="slug"
                           label="name"
                           :options="packages"
                           :searchable="true"
                           :allow-empty="false"
                           placeholder=""
                           :state="getValidationState(plan)"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <div v-if="option.id">{{ option.name }}</div>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <div v-if="option.visible">{{ option.name }}</div>
                </template>
              </multiselect>
              <b-form-invalid-feedback>{{ plan.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="Ciclo de Pagamento"
            :rules="{ required: true }"
            v-slot="cycle"
          >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Ciclo de pagamento"
              label-for="input-order-cycle"
            >
              <multiselect v-model="cycleSelected"
                           id="input-order-cycle"
                           name="cycle"
                           track-by="slug"
                           label="name"
                           :options="cycles"
                           :searchable="true"
                           :allow-empty="false"
                           placeholder=""
                           :state="getValidationState(cycle)"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <b-form-invalid-feedback>{{ cycle.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            label="Agentes adicionais"
            label-for="input-order-cycle"
            class="mb-0"
          >
            <b-input
              v-model="forms.order.aditional_agent"
              :min="0"
              type="number"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            label=""
            class="mt-3 mb-0"
          >
  
            <b-form-checkbox
              v-model="forms.order.discount"
              switch
              class="mb-1"
            >
              <label>Aplicar comissão como desconto</label>
            </b-form-checkbox>
          </b-form-group>
        </b-form>
      </validation-observer>
  
      <template v-slot:modal-footer>
        <div style="width: 100%">
          <div class="row">
            <div class="col-sm-6" style="line-height: 40px; font-size: 16px">
              Total: <price :package="forms.order.package"
                     :aditional_agent="forms.order.aditional_agent"
                     :cycle="forms.order.cycle"
                     :version="subscription.version"
                     :discount="forms.order.discount"/>
            </div>
    
            <div class="col-sm-6 text-right">
              <b-button @click="$bvModal.hide('modal-order')" class="mr-1">
                Cancelar
              </b-button>
              <b-button variant="primary" @click="handleOrder">
                Salvar
              </b-button>
            </div>
          </div>
        </div>
        
      </template>
    </b-modal>

    <b-modal
        id="modal-demo"
        centered
        title="Ativar Demo"
        title-class="font-18"
        :cancel-title="'Cancelar'"
        :ok-title="'Salvar'"
        v-if="partner.type === 'reseller' && subscription.demo === false && (subscription.package_id === 1 || subscription.package_id === 6)"
        @ok="handleOrder"
    >

      <validation-observer ref="observer" v-slot="{ handleSubmitDemo }">
        <b-form
            @submit.stop.prevent="handleSubmit(activateDemo)"
            class="form-horizontal"
        >
          <validation-provider
              name="Plano"
              :rules="{ required: true }"
              v-slot="plan"
          >
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Plano"
                label-for="input-order-plan"
            >
              <multiselect v-model="packageSelected"
                           id="input-demo-plan"
                           name="package"
                           track-by="slug"
                           label="name"
                           :options="packages"
                           :searchable="true"
                           :allow-empty="false"
                           placeholder=""
                           :state="getValidationState(plan)"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <div v-if="option.id">{{ option.name }}</div>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <div v-if="option.visible">{{ option.name }}</div>
                </template>
              </multiselect>
              <b-form-invalid-feedback>{{ plan.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              name="Ciclo de Pagamento"
              :rules="{ required: true }"
              v-slot="cycle"
          >
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Ciclo de pagamento"
                label-for="input-demo-cycle"
            >
              <multiselect v-model="cycleSelected"
                           id="input-demo-cycle"
                           name="cycle"
                           track-by="slug"
                           label="name"
                           :options="cycles"
                           :searchable="true"
                           :allow-empty="false"
                           placeholder=""
                           :state="getValidationState(cycle)"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <b-form-invalid-feedback>{{ cycle.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Agentes adicionais"
              label-for="input-demo-cycle"
              class="mb-0"
          >
            <b-input
                v-model="forms.demo.aditional_agent"
                :min="0"
                type="number"
            />
          </b-form-group>

          <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label=""
              class="mt-3 mb-0"
          >

            <b-form-checkbox
                v-model="forms.demo.discount"
                switch
                class="mb-1"
            >
              <label>Aplicar comissão como desconto</label>
            </b-form-checkbox>
          </b-form-group>
        </b-form>
      </validation-observer>

      <template v-slot:modal-footer>
        <div style="width: 100%">
          <div class="row">
            <div class="col-sm-6" style="line-height: 40px; font-size: 16px">
              Total: <price :package="forms.demo.package"
                            :aditional_agent="forms.demo.aditional_agent"
                            :cycle="forms.demo.cycle"
                            :version="subscription.version"
                            :discount="forms.demo.discount"/>
            </div>

            <div class="col-sm-6 text-right">
              <b-button @click="$bvModal.hide('modal-demo')" class="mr-1">
                Cancelar
              </b-button>
              <b-button variant="primary" @click="handleDemo">
                Salvar
              </b-button>
            </div>
          </div>
        </div>

      </template>
    </b-modal>
  </Layout>
</template>
