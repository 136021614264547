import { axios } from '@/plugins/axios';

export const getPackages = () => axios.get(`/packages`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getPrices = (slug, cycle, version) => axios.get(`/packages/${slug}/prices?cycle=${cycle}&version=${version}`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });